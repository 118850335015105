import React from "react";
import SIcons from "../SellIcons/SelIcons";
import styles from './SelScreen.module.css';

const SelScreen = ({selectId, selected}) => {
  return (
      <div className={styles.selScreen}>
        {/* <SIcons
          name="JANE DOE"
          imgSrc="./imagens/avatar_copy-512.png"
          altName="Jane Doe"
          idName="JaneDoe"
          onClick={selectId}
          selected={selected}
        /> */}
        {/* <SIcons
          name="Cópia Twitter"
          imgSrc="./imagens/w54qt1fr0st31.png"
          altName="logo twitter"
          idName="Twitter"
          onClick={selectId}
          selected={selected}
        /> */}
        {/* <SIcons
          name="POKÉDEX"
          imgSrc="./imagens/pngegg.png"
          altName="Pokedex"
          idName="Pokedex"
          onClick={selectId}
          selected={selected}
        /> */}
        {/* <SIcons
          name="QUIZ"
          imgSrc="./imagens/question.png"
          altName="Quiz"
          idName="Quiz"
          onClick={selectId}
          selected={selected}
        /> */}
        {/* <SIcons
          name="SCREEN FAVES"
          imgSrc="./imagens/tv.png"
          altName="Screen Faves"
          idName="Screens"
          onClick={selectId}
          selected={selected}
        /> */}
        {/* <SIcons
          name="SOCIAL DOGS"
          imgSrc="./imagens/dog.png"
          altName="social dogs"
          idName="Dogs"
          onClick={selectId}
          selected={selected}
        /> */}
        {/* <SIcons
          name="KERN STUDIO"
          imgSrc="./imagens/building.png"
          altName="kern studio"
          idName="Kern"
          onClick={selectId}
          selected={selected}
        /> */}
        <SIcons
          name="KERN STUDIO"
          imgSrc="./imagens/front-end.png"
          altName="kern studio"
          idName="Kern"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="HOTSPOTS SECTION"
          imgSrc="./imagens/shopify_glyph.png"
          altName="Hotspots"
          idName="Hotspots"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="PAGEBUILDER MIGRATION"
          imgSrc="./imagens/shopify_glyph.png"
          altName="PageBuilder"
          idName="PageBuilder"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="INFOS SECTION"
          imgSrc="./imagens/shopify_glyph.png"
          altName="InfosSection"
          idName="InfosSection"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="CUSTOM FILTER"
          imgSrc="./imagens/shopify_glyph.png"
          altName="CustomFilter"
          idName="CustomFilter"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="PURCHASE FORM"
          imgSrc="./imagens/shopify_glyph.png"
          altName="PurchaseForm"
          idName="PurchaseForm"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="POPUP CART"
          imgSrc="./imagens/shopify_glyph.png"
          altName="PopupCart"
          idName="PopupCart"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="RECHARGE WIDGETS"
          imgSrc="./imagens/shopify_glyph.png"
          altName="Recharge"
          idName="Recharge"
          onClick={selectId}
          selected={selected}
        />
        <SIcons
          name="MOVIE DATABASE"
          imgSrc="./imagens/front-end.png"
          altName="Movie Database"
          idName="MovieDatabase"
          onClick={selectId}
          selected={selected}
        />
        {/*<SIcons
          name="AÇÕES DA BOLSA"
          imgSrc="./imagens/PngItem_1352730.png"
          altName="moeda"
          idName="Moeda"
          onClick={selectId}
          selected={selected}
      />*/}
            {/*<SIcons
          name="LEAGUE OF BUILDS"
          imgSrc="./imagens/lob.png"
          altName="lob"
          idName="LOB"
          onClick={selectId}
          selected={selected}
        />*/}
      </div>
  );
};

export default SelScreen;
