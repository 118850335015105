import React from 'react';
import styles from './Contact.module.css'

const Contact = ({language}) => {
    return (
        <section className={styles.contato}>
            <h3>{language === 'en' ? 'Get in touch!' : 'Entre em contato!'}</h3>
            <div className={styles.iconeContato}>
                <a href="https://www.linkedin.com/in/ricardomsilvestre/" target="_blank" rel="noopener noreferrer">
                    <img src="./imagens/linkedin.png" alt="linkedin" />
                </a>
                {/*
                <a href="https://github.com/RicSilvestre" target="_blank" rel="noopener noreferrer">
                <img src="./imagens/github.png" alt="github" />
                </a>
    */}
                <a href="mailto:ricardomsilvestre@outlook.com">
                <img src="./imagens/envelope.png" alt="email" />
                </a>
            </div>
        </section>
    )
}

export default Contact
