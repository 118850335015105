import React from 'react';
import styles from './Description.module.css';
import { Hotspots, MovieDatabase, PurchaseForm, Recharge, InfosSection, CustomFilter, PopupCart, PageBuilder, Kern } from './DescriptionTypes';

const Description = ({selectedDesc, hiddenD, hide, language}) => {
    return (
        <section id="descricao" className={`${styles.descricao} ${styles.scaleInVerCenter} nes-container is-rounded`} style={{backgroundColor: "white"}} hidden={hiddenD}>
            
            {selectedDesc === 'Hotspots' && <Hotspots hideDesc={hide} language={language}/> }
            {selectedDesc === 'MovieDatabase' && <MovieDatabase hideDesc={hide} language={language}/> }
            {selectedDesc === 'Recharge' && <Recharge hideDesc={hide} language={language}/> }
            {selectedDesc === 'PurchaseForm' && <PurchaseForm hideDesc={hide} language={language}/> }
            {selectedDesc === 'PopupCart' && <PopupCart hideDesc={hide} language={language}/> }
            {selectedDesc === 'CustomFilter' && <CustomFilter hideDesc={hide} language={language}/> }
            {selectedDesc === 'InfosSection' && <InfosSection hideDesc={hide} language={language}/> }
            {selectedDesc === 'PageBuilder' && <PageBuilder hideDesc={hide} language={language}/> }
            {selectedDesc === 'Kern' && <Kern hideDesc={hide} language={language}/> }
            {selectedDesc === null && null}
        </section>
    )
}

export default Description
