import React from 'react';
import Intro from './components/Introduction/Introduction';
import SelScreen from './components/SellScreen/SelScreen';
import Descr from './components/Description/Description';
import Contact from './components/Contact/Contact';
import LangSel from './components/LangSel/LangSel';
import './App.css';

function App() {
  const [selectedID, setSelectedID] = React.useState(null);
  const [hiddenDesc, setHiddenDesc] = React.useState(true);
  const [language, setLanguage] = React.useState('en');

  function handleSelect(id) {
    setSelectedID(id);
    setHiddenDesc(false);
  }

  function handleHide() {
    setHiddenDesc(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setSelectedID(null)
  }

  function handleLanguage(lang) {
    setLanguage(lang);
  }

  return (
    <>
      <LangSel onClick={handleLanguage} language={language}/>
      <Intro language={language}/>
      <SelScreen selected={selectedID} selectId={handleSelect}/>
      <Descr selectedDesc={selectedID} language={language} hiddenD={hiddenDesc} hide={handleHide}/>
      <Contact language={language} />
    </>
  );
}

export default App;
