import React from 'react';
import styles from './SelIcons.module.css'

const SelIcons = ({name, imgSrc, altName, idName, onClick, selected}) => {
  function handleClick (e) {
    onClick(e.currentTarget.id);
    const descricao = document.getElementById("descricao");
    const offsetDesc = descricao.offsetTop;
    if (offsetDesc === 0) {
      setTimeout(() => {
        window.scrollTo({
          top: 2000,
          behavior: "smooth"
        })
      }, 300);
    } else {
      window.scrollTo({
        top: offsetDesc - ((window.innerHeight - descricao.clientHeight)/2),
        behavior: "smooth"
      })
    }
    
  } 
    return (
        <div className={styles.selecao}>
          <div id={idName} onClick={handleClick} className={`${selected && selected === idName ? styles.iconeVisited : styles.iconeImg} ${styles.iconeSite}`}>              
            <img  src={imgSrc} alt={altName}/>
          </div>
          <br/>
          <h4>{name}</h4>
        </div>
    )
}

export default SelIcons
