import React from 'react';
import styles from './LangSel.module.css'

const LangSel = ({onClick, language}) => {
  function handleLanguage (e) {
    onClick(e.currentTarget.dataset.language)
  }
  return (
      <div className={styles.langWrapper}>
          <img className={language === 'en' ? null : styles.active} onClick={handleLanguage} data-language='pt' src='./imagens/flag-brazil-2.png' alt='Brazilian portuguese/Português Brasil'/>
          <span>|</span>
          <img className={language === 'pt' ? null : styles.active} onClick={handleLanguage} data-language='en' src='./imagens/flag-usa.png' alt='United States english/Inglês Estados Unidos'/>
      </div>
  )
}

export default LangSel