import React from 'react';
import styles from './Introduction.module.css'

const Introduction = ({language}) => {
    return (
        <section className={styles.introducao}>
            <div className={styles.boasVindas}>
                <h1>Ricardo Silvestre</h1>
                <h2 className={styles.devName}>{language === 'en' ? 'Front-End Dev' : 'Dev Front-End'}</h2>
            </div>
            <div className={styles.dialogoCard}>
                <section className="message texto-inicio">
                    <div className="nes-container is-rounded" style={{backgroundColor: "white"}}>
                        {language === 'en' ? 
                        (
                            // <>
                            //     <p>Hello, visitor.</p>
                            //     <p>
                            //         My name is Ricardo, currently I work with Magento themes, but my passion for technology makes me always interested in studying and working with different stacks.
                            //     </p>
                            //     <p>
                            //         Throughout my career as a developer, I've worked with over 70 clients and their websites in the Shopify platform, apart from study and freelancing projects, that can be checked below, which include the use of Typescript, ReactJs and APIs.
                            //     </p>
                            //     <p>
                            //         Make your choice!
                            //     </p>
                            // </>
                            <>
                                <p>Hello, visitor</p>
                                <p>I'm Ric, a Front-End Developer specialized in e-commerce solutions, with a diverse portfolio spanning over 100 projects.</p>
                                <p>Apart from that, I also thrive with general websites and enhancing workflow processes, always joining logical thinking with an engaging visual approach.</p>
                                <p>Feel free to reach out so we can collaborate on building something exceptional together.</p>  
                            </>
                        ):
                        (
                            <>
                            {/* <p>Olá, visitante.</p>
                            <p> 
                                Meu nome é Ricardo, atualmente trabalho com temas de Magento, mas minha paixão por tecnologia faz com que eu esteja sempre interessado em estudar e trabalhar com diversas tecnologias
                            </p>
                            <p>
                                Ao longo da minha carreira como desenvolvedor, trabalhei com mais de 70 clientes e seus sites na plataforma Shopify, além de mais projetos de estudo e de freelancer que podem ser conferidos abaixo, incluindo uso de TypeScript, ReactJS e consumo de APIs.
                            </p>
                            <p>
                                Faça sua escolha!
                            </p> */}
                            <p>Olá, visitante</p>
                                <p>Eu sou Ric, um desenvolvedor Front-End especializado em E-Commerce, com um portfolio diverso, contendo mais de 100 projetos.</p>
                                <p>Além disso,, também tenho habilidades com desenvolvimento web generalizado e melhoria de processos de trabalho, sempre unindo pensamento lógico com um visual engajante.</p>
                                <p>Sinta-se à vontade para entrar em contato para que possamos colaborar em construir algo excepcional juntos.</p>  
                            </>
                        )
                        }
                    </div>
                </section>
            </div>
        </section>
    )
}

export default Introduction
