import React from 'react'
import styles from './Description.module.css'

export const JaneDoe = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Jane Doe</h1>
                <progress className="nes-progress is-rounded is-primary" value="100" max="100"></progress>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    'Jane Doe was a practice website done as an official website for a singer, built with sections like music, about and contact.' 
                    :
                    'Jane Doe é uma prática feita como um site oficial de uma cantora, composto por seções como música, sobre e contato.' 
                    }
                </p>
                <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                <label>
                    <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                    <span><a href="https://pratica-jane-doe.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                </label>
                <label>
                    <input type="radio" className="nes-radio" name="answer" />
                    <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                </label>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const Twitter = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Login do Twitter</h1>
                <progress className="nes-progress is-rounded is-primary" value="100" max="100"></progress>
                <p className={styles.textoEsquerda}>
                    Nesse exercício, a tela de login foi refeita do zero como treinamento de HTML, CSS e
                    pseudo-seletores.
                </p>
                <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                <label>
                    <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                    <span><a href="https://exercicio-login-twitter.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                </label>
                <label>
                    <input type="radio" className="nes-radio" name="answer" />
                    <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                </label>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const Pokedex = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                    <h1>Pokedex - TypeScript</h1>
                    <progress className="nes-progress is-rounded is-primary" value="100" max="100"></progress>
                    <p className={styles.textoEsquerda}>
                        {language === 'en' ? 
                        'A version of the famous encyclopedia of creatures from the anime Pokémon, done by consuming an APi, and with the use of Typescript'
                        :
                        'Uma versão da famosa enciclópedia das criaturas do desenho "Pokémon", feita com o consumo de uma API, e através do uso de TypeScript.'
                        }</p>
                    <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                    <label>
                        <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                        <span><a href="https://type-pokedex.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                    </label>
                    <label>
                        <input type="radio" className="nes-radio" name="answer" />
                        <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                    </label>
                    <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>TypeScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>API</span>
                        </span>
                    </div>
            </div>
        </>
    )
}

export const Quiz = ({hideDesc, language}) => {
    return (
        <>
            <h1>Quiz - TV</h1>
            <div className={styles.textoCriado}>
                <progress className="nes-progress is-rounded is-primary" value="100" max="100"></progress>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    'A quiz done accordingly to the teachings of the freeCodeCamp website, with the theme of Television Entertainment.'
                    :
                    'Quiz feito de acordo com os ensinamentos do site "freeCodeCamp". Com a temática de "Entretenimento em Televisão". Site em inglês, condizendo com a linguagem da API utilizada.'
                    }
                    </p>
                <div>
                    <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                    <div className="inputs">
                        <label>
                            <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                            <span><a href="https://tv-quiz.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                        </label>
                        <label>
                            <input type="radio" className="nes-radio" name="answer" />
                            <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                        </label>
                    </div>
                </div>
                <div className={styles.badges}>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-error`}>HTML5</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-primary`}>TypeScript</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-dark ${styles.reactColor}`}>ReactJS</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-success`}>API</span>
                    </span>
                </div>
            </div>
        </>
    )
}

export const Screens = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>{language === 'en' ? 'Movies' : 'Filmes'}</h1>
                <progress className="nes-progress is-rounded is-primary" value="100" max="100"></progress>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ?
                    'Website developed with the use of ReactJS, consuming the The Movie Database API, listing movies and showing information like synopsis, ratings and others.'
                    :
                    'Site desenvolvido utilizando ReactJS que consome a API The Movie Database, listando filmes e mostrando informações como cartaz, sinopse e nota.'
                    }
                </p>
                <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                <label>
                    <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                    <span><a href="https://screenfaves.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                </label>
                <label>
                    <input type="radio" className="nes-radio" name="answer" />
                    <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                </label>
                <div className={styles.badges}>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-error`}>HTML5</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-dark ${styles.reactColor}`}>ReactJS</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-success`}>API</span>
                    </span>
                </div>
            </div>
        </>
    )
}

export const Dogs = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Social Dogs</h1>
                <progress className="nes-progress is-rounded is-primary" value="100" max="100"></progress>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    'Practice social network for photos of dogs, with the use of several elements from ReactJS. API consumption, Login and Registering functions, comments, statistics, and others.'
                    :
                    'Exercício de uma rede social de fotos de cachorros, com uso de diversos elementos do ReactJS. Consumo de API da própria plataforma, funcões de Login e Cadastro, comentários, estatísticas e outros.'
                    }
                    </p>
                <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                <label>
                    <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                    <span><a href="https://social-dogs.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                </label>
                <label>
                    <input type="radio" className="nes-radio" name="answer" />
                    <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                </label>
                <div className={styles.badges}>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-error`}>HTML5</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-dark ${styles.reactColor}`}>ReactJS</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-success`}>API</span>
                    </span>
                </div>
            </div>
        </>
    )
}

export const Moeda = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Dashboard da Bolsa</h1>
                <progress className="nes-progress is-rounded is-dark" value="75" max="100"></progress>
                <p className={styles.textoEsquerda}>Projeto integrador de conclusão do curso Front End Coding Facebook. Consome dados do Google Finances em uma API criada pelo grupo. Para achar uma ação específica, só colocar o código dela na pesquisa.</p>
                <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                <label>
                    <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                    <span><a href="https://dashboard-de-investimentos.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                </label>
                <label>
                    <input type="radio" className="nes-radio" name="answer" />
                    <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                </label>
                <div className={styles.badges}>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-error`}>HTML5</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-success`}>API</span>
                    </span>
                </div>
            </div>
        </>
    )
}

export const LOB = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>League of Builds</h1>
                <progress className="nes-progress is-rounded is-dark" value="55" max="100"></progress>
                <p className={styles.textoEsquerda}>Projeto criado inteiramente através de ReactJS, com o intuito de proporcionar aos jogadores do jogo 'League of Legends', da empresa Riot Games, a possibilidade de calcular o dano gerado pelos personagens dependendo da escolha de equipamentos e habilidades no jogo.</p>
                <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                <label>
                    <input type="radio" className="nes-radio" name="answer" defaultChecked="true"/>
                    <span><a href="https://league-of-builds.netlify.app/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                </label>
                <label>
                    <input type="radio" className="nes-radio" name="answer" />
                    <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                </label>
                <div className={styles.badges}>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-error`}>HTML5</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-dark ${styles.reactColor}`}>ReactJS</span>
                    </span>
                    <span className={`nes-badge ${styles.gapClass}`}>
                        <span className={`${styles.mySpan} is-success`}>API</span>
                    </span>
                </div>
            </div>
        </>
    )
}

export const Kern = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Kern Studio</h1>
                <progress className="nes-progress is-rounded is-primary" value="100" max="100"></progress>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ?
                    'The website of Kern Studio, a studio specialized in architectural visualization. Project done from scratch using the design provided by the client as reference. Because of its wordpress structure, there is a lot of autonomy for the owner to edit most of the content in a simple and intuitive manner.'
                    :
                    'Site do Kern Studio, estúdio especializado em visualização de arquitetura. Projeto feito do zero de acordo com design fornecido pelo cliente, em que, por ser feito em wordpress, há bastante autonomia para editar a maioria das imagens e texto presentes de forma simples e intuitiva.'
                    }
                </p>
                <p>{ language === 'en' ? 'Proceed to page' : 'Deseja seguir para a página'}?</p>
                <label>
                    <input defaultChecked="true" type="radio" className="nes-radio" name="answer"/>
                    <span><a href="https://kernstudio.com.br/" target="_blank" rel="noopener noreferrer">{ language === 'en' ? 'Yes' : 'Sim'}</a></span>
                </label>
                <label>
                    <input type="radio" className="nes-radio" name="answer" />
                    <span id="no-click" onClick={hideDesc}>{ language === 'en' ? 'No' : 'Não'}</span>
                </label>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-dark ${styles.reactColor}`}>Wordpress</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const Hotspots = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Hotspots</h1>
                <video loop src="./imagens/projects/hotspots.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Shopify section created as a hotspot for the item's more important features."
                    :
                    'Seção de Hotspot do Shopify para demonstrar características importantes do produto.' 
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>Liquid</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const PageBuilder = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Page Builder</h1>
                <video loop src="./imagens/projects/allfamily-2.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Complete migration from pagebuilder-managed pages to Shopify sections for some pages."
                    :
                    "Migração completa de páginas gerenciadas por Page Builder para seções de Shopify."
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>Liquid</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const InfosSection = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Infos Section</h1>
                <video loop src="./imagens/projects/infos-section.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Product info section with multiple segments created from scratch."
                    :
                    "Seção de Shopify sobre informações do produto com diversos segmentos criada do zero."
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>Liquid</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const CustomFilter = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Custom Filter</h1>
                <video loop src="./imagens/projects/filter-video.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Collection filter developed to fit the functionality envisioned by the client."
                    :
                    "Filtro de coleção desenvolvido especialmente para se encaixar com a visão do cliente."
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>Liquid</span>
                        </span>
                </div>
            </div>
        </>
    )
}


export const PurchaseForm = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Purchase Form</h1>
                <video loop src="./imagens/projects/form-video.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Pre-add to cart form tailored to allow the customers to answer the properties required by the merchant."
                    :
                    "Formulário prévio à função de Adicionar ao carrinho, feito para permitir que os clientes respondessem as propriedades requiridas pela lojista."
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>Liquid</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const PopupCart = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Popup Cart</h1>
                <video loop src="./imagens/projects/popup-cart.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Popup cart created from start to finish to achieve the objective of the client, since there was no app in the app store that could fit both his styling and functionality demands. Buy-one-get-one functionality included."
                    :
                    "Popup cart criado do zero para atingir o objetivo do cliente, visto que nenhum app da loja atingia tanto seus objetivos de estilização quanto de funcionalidade. Funcionalidade 'compre um, leve outro' inclusa."
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>Liquid</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const Recharge = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Recharge Widgets</h1>
                <video loop src="./imagens/projects/smpl-recharge.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Implemented Recharge app subscription widgets and a tailored section that allowed the client to change between them for different sets of products."
                    :
                    "Implementei widgets do app Recharge, além de uma seção para alternar entre eles, que permitiam que o cliente escolhesse compra recorrente de diferentes quantidades."
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-success`}>Liquid</span>
                        </span>
                </div>
            </div>
        </>
    )
}

export const MovieDatabase = ({hideDesc, language}) => {
    return (
        <>
            <div className={styles.textoCriado}>
                <h1>Movie Database</h1>
                <video loop src="./imagens/projects/movie-database.mp4" playsinline muted autoPlay></video>
                <p className={styles.textoEsquerda}>
                    {language === 'en' ? 
                    "Movie database website created as a test from a previous hiring process that required the use of Angular and the OMDb APi. Functionalities of favorite lits and dark mode available."
                    :
                    "Site de banco de filmes criado em um processo de recrutamento anterior, que requeria o uso de Angular e da API OMDb. Funcionalidades de lista de favoritos e dark mode disponíveis."
                    }
                </p>
                <div className={styles.badges}>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>HTML5</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-primary`}>CSS3</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-warning`}>JavaScript</span>
                        </span>
                        <span className={`nes-badge ${styles.gapClass}`}>
                            <span className={`${styles.mySpan} is-error`}>Angular</span>
                        </span>
                </div>
            </div>
        </>
    )
}
